<template>
<!-- main -->
<main id="container" class="newScrollAction" >
    <h1 class="screen_out">보험클리닉</h1>
    
    <!-- section 위치표시 및 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual">메인</a>
            <a class="link_nav" data-target="#bohumclinic"><span class="hide">보험클리닉 </span>현황</a>
            <a class="link_nav" data-target="#service"><span class="hide">차별화 </span>서비스</a>
            <a class="link_nav" data-target="#award"><span class="hide">신뢰받는 </span>수상<span class="hide">내역</span></a>
            <!-- <a class="link_nav" data-target="#social"><span class="hide">생생한 보클 </span>SNS</a> -->
            <a class="link_nav" data-target="#start"><span class="hide">SINCE </span>2018</a>
            <!-- <a class="link_nav" data-target="#future"><span class="hide">보험클리닉의 </span>미래</a> -->
            <a class="link_nav" data-target="#gate"><span class="hide">보험클리닉 </span>바로가기</a>
            <a class="link_nav" data-target="#ci">CI<span class="hide"> 소개</span></a>
        </div>
    </nav>
    <!-- //section 위치표시 및 바로가기 -->

    <!-- 메인 비주얼 -->
    <section id="visual" class="section">
        <div class="top">
            <!-- <p class="tit_container">보험이 많아도, 보험이 없어도<strong>내 보험 바로.잡는<br>보험클리닉</strong></p> -->
            <div>
                <div class="box_video">
                    <video autoplay muted loop playsinline>
                        <source src="/file/video/2024_tvcf2_kakao.mp4" type="video/mp4">
                    </video>
                    <div class="bg"></div>
                </div>
            </div>
            <!-- <div class="swiper-wrapper">
                <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/tvcf_2020_certificate.mp4">
                    <div class="box_video">
                        <div class="bg" data-images-path="/video/tvcf/bohumclinic/2020_season2_1.jpg"></div>
                    </div>
                </div>
                <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2020_season2_2.mp4"><div class="box_video"><div class="bg"></div></div></div>
                <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2019_season2_1.mp4"><div class="box_video"><div class="bg"></div></div></div>
                <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2019_season2_2.mp4"><div class="box_video"><div class="bg"></div></div></div>
            </div> -->
        </div>

        <div class="bottom">
            <!-- <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2020_season2_1.jpg"><i class="icoPlay"></i></div>
                    <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2020_season2_2.jpg"><i class="icoPlay"></i></div>
                    <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2019_season2_1.jpg"><i class="icoPlay"></i></div>
                    <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2019_season2_2.jpg"><i class="icoPlay"></i></div>
                </div>
            </div> -->
            <!-- Add Navigation -->
            <!-- <button class="swiper-button-prev swiper-button-white" type="button"></button> -->
            <!-- <button class="swiper-button-next swiper-button-white" type="button"></button> -->
            <div class="list_sns">
                <p class="tit_list">보험클리닉SNS</p>
                <a class="link_sns" href="https://blog.naver.com/bohum_clinic" target="_blank" title="블로그 바로가기"><img src="" data-images-path="/image/sns/blog01.svg" alt=""></a>
                <!-- <a class="link_sns" href="https://www.instagram.com/bohum_clinic" target="_blank" title="인스타그램 바로가기"><img src="" data-images-path="/image/sns/instagram01.svg" alt=""></a> -->
                <!-- <a class="link_sns" href="https://www.facebook.com/bohumclinic" target="_blank" title="페이스북 바로가기"><img src="" data-images-path="/image/sns/facebook01.svg" alt=""></a> -->
                <a class="link_sns" href="https://www.youtube.com/channel/UCzcc4DNIpNngewyH0Lt1tpg" target="_blank" title="유투브 바로가기"><img src="" data-images-path="/image/sns/youtube01.svg" alt=""></a>
            </div>
            <button class="btn_open">
                <img class="ico ico1" src="" data-images-path="/image/svg/prev01.svg" alt="이전">
                <img class="ico ico2" src="" data-images-path="/image/svg/prev01.svg" alt="다음">
            </button>
        </div>

        <div class="video_controlor">
            <button class="volume" type="button"></button>
        </div>

    </section>
    <!-- //메인 비주얼 -->

    <!-- 현황 -->
    <section id="bohumclinic" class="section">
        <div class="contents">
            <h3 class="tit_section">보험이 궁금할 땐? <strong>보험클리닉</strong></h3>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">예전에 놓쳤던<br> 보험금까지 받았어요~<br> 보험클리닉 덕에 돈 벌었어요!</div>
                    <div class="swiper-slide">보험증권 없이도<br> 가입한 보험이 뭐가 부족한지<br> 다 분석해 줘요!</div>
                    <div class="swiper-slide">보험, 많이 들긴 했는데<br> 잘한 건지 확인하고 싶었어요!<br> 이제 마음이 놓여요! </div>
                    <div class="swiper-slide">불필요한 건 빼주고<br> 모자란 건 채워주고!<br> 꼭 보험 리모델링 받으세요! </div>
                    <div class="swiper-slide">실비 청구에 대해 문의했는데,<br> 6시간 이상 응급실에 체류 시<br> 입원 청구가 가능하다는 얘기에<br> 입원 청구를 할 수 있었어요! </div>
                    <div class="swiper-slide">수많은 보험을 직접 비교하는 게<br> 불가능한데 보험클리닉에서<br> 손쉽게 가능하니! 추천합니다! </div>
                </div>
            </div>
            <div class="group_count">
                <p class="tit_group"><strong>보험클리닉</strong>은 오늘도 고객님의<br>궁금증을 해결하고 있습니다.</p>
                <ul class="list_count">
                    <li class="item_count">
                        <p class="tit">누적 방문자 수</p>
                        <p class="num"><span>0</span>명</p>
                        <p class="source">자사 방문상담 데이터,<br> <span class="ymd"></span> 기준</p>
                    </li>
                    <li class="item_count">
                        <p class="tit">보험클리닉 지점 수</p>
                        <p class="num"><span>0</span>개</p>
                        <p class="source">자사 지점 수 데이터,<br> <span class="ymd"></span> 기준</p>
                    </li>
                    <li class="item_count">
                        <p class="tit">상담예약 수</p>
                        <p class="num"><span>0</span>건</p>
                        <p class="source">자사 상담예약 데이터,<br> <span class="ymd"></span> 기준</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //현황 -->

    <!-- 서비스 -->
    <section id="service" class="section">
        <h3 class="tit_section">보험클리닉에서만<br><strong>경험할 수 있는 차별화 서비스</strong></h3>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="box_book">
                        <img class="book" :src="imgPath+'service_book.png'" alt="보클 처방전">
                        <p class="tit">전국 어디에서나 동일하게<br> 만나는 <strong>'보클 처방전'</strong>을<br> 지금 경험해보세요.</p>
                    </div>
                    <div class="bottom">
                        <p class="tit">보험클리닉만의 <strong>'보클 처방전'</strong>으로<br> 믿을 수 있는 보험클리닉 상담 매니저가<br> 보험을 무료로 진단해 드립니다.</p>
                        <p class="desc">보클 처방전을 통해 전국 어디에서나<br> <mark>표준화된 상담 서비스를 제공할 것을 약속</mark> 합니다.</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <ul class="list_info">
                        <li class="item_info">
                            <img class="ico" :src="imgPath+'service_ico2_1.png'" alt="">
                            <p class="tit"><mark>보험진단 서비스</mark></p>
                            <p class="desc">자체 개발한 보험클리닉App으로<br> 5분 만에 보유보험 적정성 진단</p>
                        </li>
                        <li class="item_info">
                            <img class="ico" :src="imgPath+'service_ico2_2.png'" alt="">
                            <p class="tit"><mark>비교추천 서비스</mark></p>
                            <p class="desc">국내 주요 보험사와 제휴하여<br> 다수 상품 中 고객이 직접 선택</p>
                        </li>
                        <li class="item_info">
                            <img class="ico" :src="imgPath+'service_ico2_3.png'" alt="">
                            <p class="tit"><mark>보험금 청구 대행 서비스</mark></p>
                            <p class="desc">가입한 보험사 상관없이<br> 무료 보험금 청구 대행 서비스 </p>
                        </li>
                    </ul>
                </div>
                <div class="swiper-slide">
                    <ul class="list_info">
                        <li class="item_info">
                            <img class="ico" :src="imgPath+'service_ico3_1.png'" alt="">
                            <p class="tit"><mark>모든 상담이 무료</mark></p>
                            <p class="desc">서비스 제공 및 고객 안내를 주 원칙으로 하며,<br> 보험 가입을 권유하지 않습니다.</p>
                        </li>
                        <li class="item_info">
                            <img class="ico" :src="imgPath+'service_ico3_2.png'" alt="">
                            <p class="tit"><mark>원스톱 서비스 </mark></p>
                            <p class="desc">보험에 대한 진단부터 보험금 청구 등 문의를<br> 방문 시 친절하고 자세하게 안내해 드립니다.</p>
                        </li>
                        <li class="item_info">
                            <img class="ico" :src="imgPath+'service_ico3_3.png'" alt="">
                            <p class="tit"><mark>보장분석 시스템</mark></p>
                            <p class="desc">보험증권 없어도 인증을 통해 가입된<br> 모든 보험의 진단이 가능합니다.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <!-- //서비스 -->

    <!-- 수상 -->
    <section id="award" class="section">
        <h3 class="tit_section">고객에게 <strong>신뢰</strong>받는 회사</h3>
        <p class="desc_section">보험클리닉은 고객 서비스 만족을 위해<br> 최선을 다하고 있습니다. </p>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img :src="imgPath+'award_trophy06.png'" alt="2024 대한민국 소비자 선호도 1위">
                    <p class="tit">(주최:주간동아)</p>
                </div>
                <div class="swiper-slide">
                    <img :src="imgPath+'award_trophy05.png'" alt="2021 대한민국 소비자 선호도 1위">
                    <p class="tit">(주최:주간동아)</p>
                </div>
                <div class="swiper-slide">
                    <img :src="imgPath+'award_trophy04.png'" alt="2019-2020 한국소비자만족지수1위">
                    <p class="tit">(주최:한경비즈니스)</p>
                </div>
                <div class="swiper-slide">
                    <img :src="imgPath+'award_trophy03.png'" alt="2020 고객이신뢰하는브랜드 대상 ">
                    <p class="tit ml-35">(주최:한경비즈니스)</p>
                </div>
                <div class="swiper-slide">
                    <img :src="imgPath+'award_trophy02.png'" alt="2019 고객만족브랜드 대상">
                    <p class="tit">(주최:주간동아)</p>
                </div>
                <div class="swiper-slide">
                    <img :src="imgPath+'award_trophy01.png'" alt="2019 한국고객만족도 1위">
                    <p class="tit">(주최:한경비즈니스)</p>
                </div>
            </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
    </section>
    <!-- //수상 -->

    <!-- SNS -->
    <!-- <section id="social" class="section dataTarget" data-scroll-cut-line="10">
        <h2 class="tit_section">생생한 <strong>보험클리닉 이야기</strong></h2>
        <div class="social-wrap">
            <div class="social-btn">
                <button type="button" data-type="instagram" class="I on" title="인스타그램">인스타그램</button>
                <button type="button" data-type="youtube" class="Y on" title="유튜브">유튜브</button>
                <button type="button" data-type="facebook" class="F" title="페이스북">페이스북</button>
                <button type="button" data-class="B" class="B">블로그</button>
            </div>
            <ul>
                <li data-attr-type="type">
                    <a data-href="href" target="_blank">
                        <div class="top">
                            <p class="txt" data-text="title" data-clamp="3"></p>
                            <p class="hash-tag line_clamp1" data-text="description" data-clamp="1"></p>
                        </div>
                        <div class="thumb"></div>
                    </a>
                </li>
            </ul>
            <img class="bg_phone" :src="imgPath+'social_phone.png?v=230127'" alt="">
        </div>
        <div class="list_sns">
            <p class="tit_list">보험클리닉SNS</p>
            <a class="link_sns" href="//www.youtube.com/channel/UCzcc4DNIpNngewyH0Lt1tpg" target="_blank" title="유튜브 바로가기"><img class="ico" src="" data-images-path="/image/sns/youtube01.svg"></a>
            <a class="link_sns" href="//www.facebook.com/bohumclinic" target="_blank" title="페이스북 바로가기"><img class="ico" src="" data-images-path="/image/sns/facebook01.svg"></a>
            <a class="link_sns" href="//www.instagram.com/bohum_clinic" target="_blank" title="인스타그램 바로가기"><img class="ico" src="" data-images-path="/image/sns/instagram01.svg"></a>
            <a class="link_sns" href="//blog.naver.com/bohum_clinic" target="_blank"><img class="ico" src="" data-images-path="/image/sns/blog01.svg"></a>
        </div>
    </section> -->
    <!-- //SNS -->

    <!-- 2018  -->
    <section id="start" class="section">
        <div class="contents">
            <!-- <p class="year">SINCE <strong>0</strong></p> -->
            <p class="tit">보험클리닉 런칭 이후<br>수많은 보험고민을 해결하고 있습니다.</p>
            <p class="desc">
                <span>우리는 <mark>고객의 니즈에 맞춰 능동적으로</mark></span><br>
                <span><mark>보험을 찾을 수 있는 방법에 대해 고민</mark>했습니다.</span><br>
                <span>한 곳에서 보험에 대해<mark>모든 궁금증을</mark></span><br>
                <span><mark>해결할 수 있는 방법에 대해서도 고민</mark>했습니다.</span>
            </p>
        </div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" :style="'background-image:url('+imgPath+'start_bg001.jpg)'"></div>
                <div class="swiper-slide" :style="'background-image:url('+imgPath+'start_bg002.jpg)'"></div>
                <div class="swiper-slide" :style="'background-image:url('+imgPath+'start_bg003.jpg)'"></div>
                <div class="swiper-slide" :style="'background-image:url('+imgPath+'start_bg004.jpg)'"></div>
            </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
    </section>
    <!-- //2018  -->

    <!-- 미래 -->
    <!-- <section id="future" class="section">
        <h3 class="tit_section"><strong>보험클리닉</strong>의 미래</h3>
        <p class="desc_section">보험클리닉은 번화가 상권을 중심으로 확장 중이며,<br>항상 고객님의 가까이에서 고민을 해결하고 있습니다.</p>
        <div class="map">
            <i class="seoul">서울</i>
            <i class="incheon">인천</i>
            <i class="gyeonggi">경기</i>
            <i class="gangwon">강원</i>
            <i class="chungnam">충남</i>
            <i class="chungbuk">충북</i>
            <i class="daejeon">대전</i>
            <i class="gyeongbuk">경북</i>
            <i class="daegu">대구</i>
            <i class="jeonbuk">전북</i>
            <i class="gwangju">광주</i>
            <i class="gyeongnam">경남</i>
            <i class="ulsan">울산</i>
            <i class="busan">부산</i>
            <i class="jeonnam">전남</i>
            <i class="jeju">제주</i>
            <img class="line" :src="imgPath+'future_line.svg'" alt="">
            <img class="bg" :src="imgPath+'future_map.png'" alt="전국지도">
        </div>
        <ul class="list_count isAppear">
            <li class="item_count"><p>2024년<br><mark>보험상담매니저</mark></p><p><em class="animateNumber">1,000</em> 명</p></li>
            <li class="item_count"><p>2024년<br><mark>총 전국지점망</mark></p><p><em class="animateNumber">500</em> 개</p></li>
            <li class="item_count"><p><mark>한국소비자만족지수</mark><br>(주최 : 한경비즈니스)<br>2019-2020</p><p><em>1</em> 위</p></li>
        </ul>
    </section> -->
    <!-- //미래 -->

    <!-- 두가지 방법 -->
    <section id="gate" class="section">
        <div class="inner">
            <div class="txt_area shop">
                <p>가까운 보험샵(Shop)으로</p>
                <p>방문하는</p>
                <p>보험클리닉</p>
            </div>
            <div class="txt_area fa">
                <p>고객님이 계신 곳</p>
                <p class="pt-30"><span><i>카</i><i>페</i>, </span><span><i>사</i><i>무</i><i>실</i>, </span><span><i>집</i></span></p>
                <p>어디든지 찾아가는</p>
                <p>보험클리닉</p>
            </div>
            <a class="link_more shop" href="//m.bohumclinic.com/shop" target="_blank"><span>입장</span></a>
            <a class="link_more fa" href="//m.bohumclinic.com/visit" target="_blank"><span>입장</span></a>
        </div>
    </section>
    <!-- //두가지 방법 -->

    <!-- CI -->
    <section id="ci" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 <strong>BI소개</strong></h3>
            <div class="download">
                <div class="box_logo">
                    <img src="" data-images-path="/image/logo/bohumclinic_extension01.svg" alt="로고">
                </div>
                <p class="txt">보험클리닉은 피드백과 소통이 이루어지는 이미지를 통해 누구나 보험클리닉을 접함으로써 보험에 관한 모든 것을 묻고 상담하는 과정을 형상화하였습니다.<br><br> 또한 방문, 상담, 체험하는 과정을 고래의 이미지를 통해 밝은 미래를 준비하고 꿈꿀 수 있다는 메시지를<br>전달하고 있습니다.<br><br> 전체적으로 <strong>밝고 미래지향적인 이미지로 간결하고<br>생동감 있게 표현</strong>하였습니다.</p>
            </div>
            <div class="system isAppear">
                <p class="tit_system">Color System</p>
                <ul class="list_system">
                    <li class="item_system">
                        <p class="tit">BC_BLUE</p>
                        <p class="cmyk"><strong>CMYK</strong>C100 / M26 / Y0 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R0 / G142 / B211</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">BC_GREEN</p>
                        <p class="cmyk"><strong>CMYK</strong>C53 / M0 / Y100 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R133 / G196 / B64</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">BC_GRAY</p>
                        <p class="cmyk"><strong>CMYK</strong>C0 / M0 / Y0 / K65</p>
                        <p class="rgb"><strong>RGB</strong>R119 / G120 / B123</p>
                    </li>
                </ul>
            </div>
            <div class="official">
                <h3 class="tit_official">공식 이미지</h3>
                <ul class="list_official">
                    <li class="item_official">
                        <div class="tit">Basic</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_extension01.svg" alt="">
                    </li>
                    <li class="item_official">
                        <div class="tit">Character</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_character.svg" alt="">
                    </li>
                    <li class="item_official">
                        <div class="tit">Extension</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_basic.svg" alt="">
                    </li>
                    <!-- <li class="item_official">
                        <div class="tit">Extension_02</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_extension02.svg" alt="">
                    </li> -->
                </ul>
                <p class="desc_official">시그니쳐는 브랜드명과 캐릭터, 사명의 조합으로 이루어진 형태를 기본으로 사용합니다.  어플리케이션에 따라 위와 같이 확장 형태 및 캐릭터의 단독 사용이 가능합니다.<br><br>또한 텍스트(문자)로의 보험클리닉 브랜드명 사용시, ‘보험클리닉 피플라이프’(전체 브랜드명) 혹은  ‘보험클리닉’(브랜드명 단독 사용)으로 사용 가능하며, ‘피플라이프 보험클리닉’으로의 사용을 금합니다.</p>
            </div>
        </div>
    </section>
    <!-- //CI -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/brand/bohum/'
        }
    },
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/brand/bohum.scss';
</style>
